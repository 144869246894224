import Link from "next/link";
import styled, { useTheme } from "styled-components";
import { mediaQueriesRanged } from "~/styles/breakpoints";
import RecipeImage from '~/components/recipe-image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCaretDown, faCaretUp, faMinus } from "@fortawesome/free-solid-svg-icons";

interface SeeMoreCardProps {
  dest: string
}

/**
 * Renders a see more card
 */
const SeeMoreCard: React.FC<SeeMoreCardProps> = (props: SeeMoreCardProps) => {
  return (
    <Card>
      <Link href={props.dest}>
        <CardContent>
          <div className="cta">
            see more
          </div>
          <div className="arrow">
            <FontAwesomeIcon
              size="xl"
              icon={faArrowRight}
              width={14}
            />
          </div>
        </CardContent>
      </Link>
    </Card>
  )
}

export default SeeMoreCard;

const Card = styled.div`
  cursor: pointer;
  position: relative;
  background-color: #f7f7f2;
  border: 1px solid #e6e6e6;
  transition: box-shadow .2s ease-in-out;
  vertical-align: middle;
  text-align: center;

  display: flex;
  flex-direction: row;

  transition: box-shadow 0.3s ease; /* Add transition for smooth effect */

  width: 165px;

  @media(${mediaQueriesRanged.md}) {
    width: 194px;
  }

  @media(${mediaQueriesRanged.lg}) {
    width: 260px;
  }

  @media(${mediaQueriesRanged.xl}) {
    width: 260px;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    width: inherit;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add shadow effect on hover */
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  .cta {
    font-weight: 700;
    font-size: 1.2rem;
    font-style: italic;
  }

  .arrow {
    padding-left: 5px;
  }
`
