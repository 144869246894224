import { GetServerSideProps } from "next";
import Head from "next/head";
import { useMemo } from "react";
import styled from "styled-components";
import CollectionRow from "~/components/collection-row/v2";
import { AuthenticationStatus } from "~/contexts/authn";
import { GQL_LIST_COLLECTIONS } from "~/graphql/collections";
import useAuthN from "~/hooks/use-authn";
import { makeClientSsr } from "~/lib/apollo-wrapper/make-client";
import { getSsrUserID, isAuthenticatedSsr } from "~/lib/apollo-wrapper/tokens";
import { mediaQueriesRanged } from "~/styles/breakpoints";
import { handleApolloError } from "~/utils/gql";
import { getLogger } from "~/utils/logging";

const logger = getLogger("RecipesPage");

interface ServerProps {
  collections: any[];
  nextPageToken: String;
}

export const getServerSideProps: GetServerSideProps<ServerProps> = async (
  context
) => {
  // create client
  const client = makeClientSsr(context);
  const isAuthenticated = isAuthenticatedSsr(context);
  const userID = getSsrUserID(context);

  logger.debug(`collections, isAuthn="${isAuthenticated}", userID="${userID}"`);

  // todo handle errors
  const { data } = await client
    .query({
      query: GQL_LIST_COLLECTIONS,
      variables: {
        collectionsPageSize: 10,
        collectionsPageToken: "", // todo
        userID: userID,
        isAuthenticated: isAuthenticated,
        recipeCount: 4,
      },
    })
    .then(handleApolloError)
    .then(
      (res) => res,
      (err) => {
        logger.error(`failed`, err);
        return Promise.reject(err);
      }
    );

  const serverProps: ServerProps = {
    collections: data.collections.collections,
    nextPageToken: data.collections.nextPageToken,
  };
  return { props: serverProps };
};

function CollectionsPage(props: ServerProps) {
  const { collections, nextPageToken } = props;
  const { status } = useAuthN();

  const pageBody = useMemo(() => {
    logger.debug(`updating collection`, collections)

    const collectionRows = collections.map(c => (
      <CollectionRow key={c.collectionId} collection={c} />
    ))

    // return collectionRows[0]

    return (
      <Container>
        {collectionRows}
      </Container>
    )
  }, [collections])

  // todo: better loading state
  if (status === AuthenticationStatus.BOOTING) {
    return <></>;
  }

  return (
    <>
      <Head>
        <title>reheat.ai 🔥</title>
        <meta name="description" content="your endless cookbook" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {pageBody}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  grid-column: col-start 1 / col-end -1;
  gap: 16px;
`;

export default CollectionsPage;
