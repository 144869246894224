import { hashStringToNumber } from "~/utils/strings";

export function getDefaultRecipeImg(id: string): string {
  const code = hashStringToNumber(id)
  const options: string[] = [
    '/img/empty-plate-1.png',
    '/img/empty-plate-2.png',
    '/img/empty-plate-3.png',
    '/img/empty-plate-4.png',
    '/img/empty-plate-5.png',
    '/img/empty-plate-6.png',
  ]
  const ix = code % options.length
  const out = options[ix]
  return out
}
