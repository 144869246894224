import React from 'react';
import Image from 'next/image';
import { getDefaultRecipeImg } from "~/lib/failover-image";
import styled from 'styled-components';

interface RecipeImageProps {
  id: string;
  src?: string;
  alt: string;
  isDefaultImage: boolean;
}

const RecipeImage: React.FC<RecipeImageProps> = (props: RecipeImageProps) => {
  return (
    <RecipeImageDiv>
      <div className="recipe-image-wrapper">
        <Image
          src={props.src || getDefaultRecipeImg(props.id)}
          alt={props.alt}
          fill
          className={props.isDefaultImage ? "recipe-image-content blurred" : "recipe-image-content"}
        />
        {props.isDefaultImage && (
          <>
            <div className="darken-overlay"></div>
            <div className="blurred-text-overlay">-reheating-</div>
          </>
        )}
      </div>
    </RecipeImageDiv>
  )
}

export default RecipeImage;

const RecipeImageDiv = styled.div`
  width: 100%;
  postition: relative;

  .recipe-image-wrapper {
    padding-top: 66%; /* Set the desired aspect ratio (e.g., 4:3 = 75%) */
    position: relative;
  }

  .recipe-image-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .recipe-image-content.blurred {
    filter: blur(1.6px);
  }

  .blurred-text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 0.9em;
    font-weight: 500;
    font-style: italic;
  }

  .darken-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* adjust the last value to darken more or less */
  }
`
