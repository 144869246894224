import styled from "styled-components";
import { getLogger } from "~/utils/logging";
import RecipeListCard from "~/components/recipe-list-card";
import Carousel from "~/components/recipe-carousel";
import { mediaQueriesRanged } from "~/styles/breakpoints";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SeeMoreCard from "~/components/see-more-card";

const logger = getLogger("CollectionRow");

interface CollectionRowProps {
  collection: {
    collectionId: String;
    name: String;
    recipes: {
      recipes: any[];
      nextPageToken: String;
    };
  };
}

const CollectionRow = (props: CollectionRowProps) => {
  const { name, recipes, collectionId } = props.collection;

  logger.debug("data", recipes)

  const recipeCards = recipes.recipes.map((r: any, ix: number) => (
    <RecipeListCard
      key={`${r.recipeId}-${ix.toString()}`}
      id={r.recipeId}
      slug={r.urlSlug}
      title={r.title}
      imgSrc={r.defaultImageUrl || r.remixedFrom?.defaultImageUrl}
      isDefaultImage={r.defaultImageUrl ? false : true}
      createdBy={r.createdBy}
      voteScore={r.voteScore}
    />
  ))


  const collectionPath: string = `/collections/${collectionId}`

  recipeCards.push(<SeeMoreCard dest={collectionPath} />)

  const arrow = (
    <FontAwesomeIcon
      size="sm"
      icon={faArrowRight}
      width={16}
    />
  )

  return (
    <Container>
      <SectionHeader>
        <Link href={collectionPath}>
          <SectionHeaderText>
            {name}{"    "}{arrow}
          </SectionHeaderText>
        </Link>
      </SectionHeader>
        {/* <RecipeCardContainer> */}
        <Carousel>
          {recipeCards}
        </Carousel>
        {/* </RecipeCardContainer> */}
    </Container>
  )
}

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;

  a {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Makes the link color the same as the surrounding text */
  }
`

const SectionHeaderText = styled.h2`
  font-weight: 700;
  padding-top: 4px;
  // text-decoration: underline;
  border-bottom: 2px solid black;
  padding-right: 5px;
`

const RecipeCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Container = styled.div`
  grid-column: col-start 0/col-end 0;

  @media ${mediaQueriesRanged.sm} {
    grid-column: col-start 1/col-end 2;
  }

  @media ${mediaQueriesRanged.md} {
    grid-column: col-start 1/col-end 4;
  }

  @media ${mediaQueriesRanged.lg} {
    grid-column: col-start 1/col-end 8;
  }

  @media ${mediaQueriesRanged.xl} {
    grid-column: col-start 1/col-end 8;
  }
`

export default CollectionRow;
