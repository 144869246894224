import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { getLogger } from "~/utils/logging";

const logger = getLogger('carousel')

interface CarouselProps {
}

const Carousel: React.FC<React.PropsWithChildren<CarouselProps>> = (props) => {
  const { children } = props;
  // storing some widths
  const [canScroll, setCanScroll] = useState<{left: boolean, right: boolean}>({left: false, right: false})
  // Ref for the scroll container
  const containerRef = useRef<HTMLDivElement>(null);

  // callback to determine if we can scroll more
  const updateCanScroll = useCallback(() => {
    if (containerRef.current) {
      // width of the visible area
      const screenWidth = containerRef.current.clientWidth;
      // width of the scrolling area
      const scrollWidth = containerRef.current.scrollWidth;
      // furthest right scroll
      const maxOffset = scrollWidth - screenWidth;
      // current scroll
      const currentOffset = containerRef.current.scrollLeft;
      // compute left/right
      const left = scrollWidth > screenWidth && currentOffset > 0;
      const right = scrollWidth > screenWidth && currentOffset < maxOffset;
      // set state
      setCanScroll({left, right})
    }
  }, [])

  // tracks resize events to track the scrolling area widths
  useEffect(() => {
    // Initial width calculation
    updateCanScroll()
    // Attach the resize event listener
    window.addEventListener("resize", updateCanScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateCanScroll);
    };
  }, [children, updateCanScroll]);

  // Add scroll event listener when the component mounts
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", updateCanScroll);
    }

    // Remove the scroll event listener when the component unmounts
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateCanScroll);
      }
    };
  }, [updateCanScroll]);

  const scroll = (amount: number, direction: 'right' | 'left') => {
    if (containerRef.current) {
      const newAmount = amount * (direction == 'left' ? 1 : -1);
      const currentPos = containerRef.current.scrollLeft
      let targetPos = currentPos - newAmount
      // width of the visible area
      const screenWidth = containerRef.current.clientWidth;
      // width of the scrolling area
      const scrollWidth = containerRef.current.scrollWidth;
      // furthest right scroll
      const maxOffset = scrollWidth - screenWidth;
      // handle boundaries
      if (direction === 'left') {
        targetPos = Math.max(targetPos, 0);
      } else if (direction == 'right') {
        targetPos = Math.min(targetPos, maxOffset);
      }
      // scroll to position
      containerRef.current.scrollTo({
        left: targetPos,
        behavior: "smooth",
      })
    }
  }

  // Function to handle scrolling right
  const scrollPageRight = useCallback(() => {
    if (containerRef.current) {
      scroll(containerRef.current.clientWidth, 'right');
    }
  }, []);

  // Function to handle scrolling left
  const scrollPageLeft = useCallback(() => {
    if (containerRef.current) {
      scroll(containerRef.current.clientWidth, 'left')
    }
    // scroll(scrollingWidth, 'left')
  }, []);

  const leftArrow = useMemo(() => {
    if (!canScroll.left) {
      return <></>
    }
    return (
      <LeftArrowContainer>
        <FontAwesomeIcon
          icon={faChevronCircleLeft}
          onClick={scrollPageLeft}
        />
      </LeftArrowContainer>
    )
  }, [canScroll.left, scrollPageLeft])

  const rightArrow = useMemo(() => {
    if (!canScroll.right) {
      return <></>
    }
    return (
      <RightArrowContainer>
        <FontAwesomeIcon
          icon={faChevronCircleRight}
          onClick={scrollPageRight}
        />
      </RightArrowContainer>
    )
  }, [canScroll.right, scrollPageRight])

  return (
    <Container>
      <Frame>
        <Scrollable ref={containerRef}>
          <MovingContainer>
            {children}
          </MovingContainer>
        </Scrollable>
      </Frame>
      {leftArrow}
      {rightArrow}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  position: relative; /* Needed for absolute positioning inside */
`

const Frame = styled.div`
  overflow-x: hidden;
  overflow-y: visible;
`

// this is the box that implements scrolling
const Scrollable = styled.div`
  width: 100%;

  // make it scroll horizontally
  overflow-x: scroll;

  // make vertical overflow visible (shadow)
  overflow-y: visible;

  // Enable smooth scrolling on iOS
  -webkit-overflow-scrolling: touch;
`

// this is the div that slides across
const MovingContainer = styled.div`
  // width: 100%;
  /* Use flexbox to make the items display horizontally */
  display: flex;
  gap: 16px;

  padding-left: 20px;
  padding-right: 20px;
`

const ArrowContainer = styled.div`
  height: 100%;
  width: 40px;
  z-index: 20;
  position: absolute;

  display: flex;
  align-items: center; /* Vertically center the button */
  justify-content: center; /* Horizontally center the button (optional) */

  /* Allows clicks to pass through the right-content */
  // pointer-events: none;
`

const LeftArrowContainer = styled(ArrowContainer)`
  top: 0;
  left: -20px;

  // gradient
  background: linear-gradient(to left, rgba(255, 255, 255, 0), ${props => (props.theme.colors.powderWhite)} 50%);
`

const RightArrowContainer = styled(ArrowContainer)`
  top: 0;
  right: -20px;

  /* Fade-to-white effect */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), ${props => (props.theme.colors.powderWhite)} 50%);
`

export default Carousel;
