import Link from "next/link";
import styled, { useTheme } from "styled-components";
import { mediaQueriesRanged } from "~/styles/breakpoints";
import RecipeImage from '~/components/recipe-image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faMinus } from "@fortawesome/free-solid-svg-icons";

interface RecipeListCardProps {
  id: string
  slug: string
  title: string
  imgSrc?: string
  isDefaultImage: boolean
  createdBy: {
    username: string
  }
  voteScore: number
}

/**
 * Renders a recipe list item on the recipe listing page
 */
const RecipeListCard: React.FC<RecipeListCardProps> = (props: RecipeListCardProps) => {
  const { id, title, slug, voteScore } = props;
  const slugComputed: string = !!slug ? slug : id
  const dest: string = `/recipes/${slugComputed}`

  const imgSrc = props.imgSrc
  const imgAlt = `depicts a cooked recipe for ${title.toLowerCase()}`
  // todo: handle deleted users
  const author: string = `@${props.createdBy.username}`

  const theme = useTheme()

  const getColor = (x: number) => {
    if (!theme || x == 0) {
      return "grey"
    }
    if (x > 0) {
      return theme.colors.upvoteGreen
    }
    return theme.colors.downvoteRed
  }

  const getIcon = (x: number) => {
    if (x > 0) {
      return (
        <div className="upvote">
          <FontAwesomeIcon icon={faCaretUp}/>
        </div>
      )
    }
    if (x < 0) {
      return (
        <div className="downvote">
          <FontAwesomeIcon icon={faCaretDown}/>
        </div>
      )
    }
    return (
      <div className="novote">
        <FontAwesomeIcon icon={faMinus}/>
      </div>
    )
  }

  const votes = (
    <VoteScore fontColor={getColor(voteScore)}>
      <div className="icon-box">
        {getIcon(voteScore)}
      </div>
      {voteScore !== 0 && <div className="score-box">{voteScore}</div>}
    </VoteScore>
  )

  return (
    <Card>
      <Link href={dest}>
        <CardUpper>
        <RecipeImage
          id={id}
          src={imgSrc}
          alt={imgAlt}
          isDefaultImage={props.isDefaultImage}
        />
        </CardUpper>
        <CardLower>
          <CardTile><h3>{title}</h3></CardTile>
          <Author>{author}</Author>
          {votes}
        </CardLower>
      </Link>
    </Card>
  )
}

export default RecipeListCard;

const Card = styled.div`
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  transition: box-shadow .2s ease-in-out;

  display: flex;
  flex-direction: row;

  transition: box-shadow 0.3s ease; /* Add transition for smooth effect */

  width: 165px;

  @media(${mediaQueriesRanged.md}) {
    width: 194px;
  }

  @media(${mediaQueriesRanged.lg}) {
    width: 260px;
  }

  @media(${mediaQueriesRanged.xl}) {
    width: 260px;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
    width: inherit;
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add shadow effect on hover */
  }
`

const CardUpper = styled.div`
  width: 100%;
`

const CardLower = styled.div`
  position: relative;
  display: flex;
  vertical-align: top;
  text-align: left;
  flex-direction: column;
  gap: 5px;

  height: 100px; // can go to 120px when we have more content
  padding: 10px;

  @media(${mediaQueriesRanged.md}) {
    height: 110px;
  }

  @media(${mediaQueriesRanged.lg}) {
    height: 110px;
  }

  @media(${mediaQueriesRanged.xl}) {
    height: 110px;
  }
`

const CardTile = styled.div`
  // this handles text overflow with elipses
  // needs to be adapted for non-webkit browsers
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  h3 {
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }
`

const Author = styled.div`
  font-size: 1rem;
  font-weight: 300;
  padding: 0;
  margin: 0;
  color: grey;

  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Display ellipsis (...) when text overflows */
`

const VoteScore = styled.div<{fontColor: string}>`
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 30px;
  vertical-align: center;
  justify-content: left;
  align-items: center; /* Align items vertically in the center */
  height: 18.5px;


  color: ${props => props.fontColor};

  .score-box {
    display: flex;
    flex-direction: row;
    font-size:      1rem;
    font-weight:    300;
    height:         100%;
    font-family:    Arial, Helvetica, sans-serif;
    font-size:      14px;
    align-items:    center;
    font-weight:    700;
    width:          20px
  }

  .icon-box {
    position: relative;
    width: 10px;
    height: 100%;
  }

  .downvote {
    position: absolute;
    width: 10px;
    top: 0px;
    left: 0px;
  }

  .upvote {
    position: absolute;
    width: 10px;
    top: 2px;
    left: 0px;
  }

  .novote {
    position: absolute;
    width: 10px;
    top: 3px;
    left: 0px;
  }
`
